<script lang="ts" setup>
import type { LogicWrapper } from '@integration-layer/ampliance/schemas/logic-wrapper-schema.localized'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: LogicWrapper
  firstPageContent?: boolean
}>()

type PaddingOptionsType = 'center' | 'left' | 'right' | 'fullWidth'

const { isLogged } = useLogin()

const widthOptions: any = {
  '100': 'col-span-12',
  '50': 'col-span-6',
  '33': 'col-span-4',
  '25': 'col-span-3',
}

const paddingOptions: Record<PaddingOptionsType, string> = {
  center: 'xl:py-6xl xl:px-36 lg:px-28 md:py-4xl md:px-36 py-lg px-[4.37rem]',
  left: 'xl:py-8xl xl:pr-lg xl:pl-[23rem] lg:py-5xl lg:pr-md lg:pl-48 md:pt-4xl md:pb-3 md:px-[5.3rem] px-[2.65rem] pt-lg pb-xs',
  right:
    'xl:py-8xl xl:pl-lg xl:pr-[23rem] lg:py-5xl lg:pl-md lg:pr-48 md:pb-4xl md:pt-3 md:px-[5.3rem] px-[2.65rem] pt-xs pb-lg',
  fullWidth: 'xl:px-[23rem] lg:px-48 px-sm',
}

const data = await useComponentResponse<LogicWrapper>(props)
const showInCountry = computed(() => {
  const showInAllCountries = data.value?.showInAllCountries
  const showInCountry = data.value?.showInCountry

  // If both are undefined, return true
  if (showInAllCountries === undefined && showInCountry === undefined) {
    return true
  }

  // If showInAllCountries is false, check showInCountry
  if (showInAllCountries === false) {
    return showInCountry !== false
  }

  // Otherwise, return true
  return true
})
const showComponent = computed(() => {
  return (
    data.value &&
    (data.value.showTo === 'All' ||
      (data.value.showTo === 'Logged' && isLogged) ||
      (data.value.showTo === 'Guest' && !isLogged))
  )
})

const setFirstPageContent = (index: number, dimension: string) => {
  switch (dimension) {
    case '100':
      return index === 0
    case '50':
      return index < 2
    default:
      return false
  }
}
</script>

<template>
  <div
    v-if="data && showComponent && showInCountry"
    class="bg-background-secondary border-background-secondary flex flex-col gap-px border-b lg:grid lg:grid-cols-12"
  >
    <div
      v-for="(logic, index) in data.contents"
      :key="`logic-wrapper-${index}`"
      :class="`md:${widthOptions[logic.dimension!]!} w-full content-center order-${logic.contentOrderMobile ? Number(logic.contentOrderMobile) - 1 : index} lg:order-${index}`"
      :data-testid="
        logic?.content?._meta?.schema.includes('https://armani.com/hero-banner')
          ? `banner-to-plp-${index}`
          : 'other-banner'
      "
    >
      <div
        :class="
          logic.padding
            ? paddingOptions[logic.alignment as PaddingOptionsType]
            : ''
        "
      >
        <AmplienceDynamicContent
          :components="connectComponents([logic.content], locale, vse)"
          :first-page-content="
            firstPageContent && setFirstPageContent(index, logic.dimension)
          "
          :width="logic.dimension"
        />
        <AmplienceDynamicContent
          :components="
            connectComponents(
              logic.shopTheLook ? [logic.shopTheLook] : [],
              locale,
              vse
            )
          "
        />
      </div>
    </div>
  </div>
</template>
